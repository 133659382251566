import React from "react";
import "./Modules.css";
//import Submodules from "./Submodules";

class Modules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenu: "modules",
      subMenu: "subModules"
    };
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubModClick = this.handleSubModClick.bind(this);
  }

  toggleHamburger() {
    if (this.state.navMenu === "modules") {
      this.setState({
        navMenu: "modules active",
      });
    } else {
      this.setState({
        navMenu: "modules",
      });
    }
  }

  handleClick(module) {
    this.props.passedModule(module);

    /*this.setState({
      navMenu: "modules",
    });*/
    this.setState({
      navMenu: "modules",
    });
  }

  handleSubModClick(submodule) {
    this.props.subModuleClick(submodule);

    /*this.setState({
      navMenu: "modules",
    });*/
    this.setState({
      navMenu: "modules",
     // subMenu: "subModules"
    });
  }

  render() {
    return (
      <div className="nav-bar">
        <div className="hamburger" onClick={this.toggleHamburger}>
          <div className="line"> </div>
          <div className="line"> </div>
          <div className="line"> </div>
        </div>
        <div className={this.state.navMenu}>
          <h3 onClick={() => this.handleClick("PART01")}>
            LEADERSHIP - Quick Review
          </h3>
          
        </div>
        
      </div>
    );
  }
}

export default Modules;
