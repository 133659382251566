
import "./NoPage.css";
const NoPage = () => {
  return (
    <div className="bg">
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
      YOU DIDN'T SAY THE MAGIC WORD
      <br></br> 
    </div>
  );
};

export default NoPage;
