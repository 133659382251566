import React from "react";
import "./Modules.css";
//import Submodules from "./Submodules";

class Modules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenu: "modules",
      subMenu: "subModules"
    };
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubModClick = this.handleSubModClick.bind(this);
  }

  toggleHamburger() {
    if (this.state.navMenu === "modules") {
      this.setState({
        navMenu: "modules active",
      });
    } else {
      this.setState({
        navMenu: "modules",
      });
    }
  }

  handleClick(module) {
    this.props.passedModule(module);

    /*this.setState({
      navMenu: "modules",
    });*/
    this.setState({
      navMenu: "modules",
    });
  }

  handleSubModClick(submodule) {
    this.props.subModuleClick(submodule);

    /*this.setState({
      navMenu: "modules",
    });*/
    this.setState({
      navMenu: "modules",
     // subMenu: "subModules"
    });
  }

  render() {
    return (
      <div className="nav-bar">
        <div className="hamburger" onClick={this.toggleHamburger}>
          <div className="line"> </div>
          <div className="line"> </div>
          <div className="line"> </div>
        </div>
        <div className={this.state.navMenu}>
          <h3 onClick={() => this.handleClick("D1")}>
            D1
          </h3>
          <h3 onClick={() => this.handleClick("D2")}>
            D2
          </h3>
          <h3 onClick={() => this.handleClick("D3")}>
            D3
          </h3>
          <h3 onClick={() => this.handleClick("D4")}>
            D4
          </h3>
          <h3 onClick={() => this.handleClick("D5")}>
            D5
          </h3>
          <h3 onClick={() => this.handleClick("D6")}>
            D6 - ITGCs
          </h3>
          <h3 onClick={() => this.handleClick("D1M1")}>
            D1M1 - Planning Audits Part 1
          </h3>
          <h3 onClick={() => this.handleClick("D1M2")}>
            D1M2 - Planning Audits Part 2
          </h3>
          <h3 onClick={() => this.handleClick("D1M3")}>
            D1M3 - Conducting Audits Part 1
          </h3>
          <h3 onClick={() => this.handleClick("D1M4")}>
            D1M4 - Conducting Audits Part 2
          </h3>
          <h3 onClick={() => this.handleClick("D2M1")}>
            D2M1 - IT Governance Part 1
          </h3>
          <h3 onClick={() => this.handleClick("D2M2")}>
            D2M2 - IT Governance Part 2
          </h3>
          <h3 onClick={() => this.handleClick("D2M3")}>
            D2M3 - IT Management
          </h3>
          <h3 onClick={() => this.handleClick("D3M1")}>
            D3M1 - IS Project Governernance and Management
          </h3>
          <h3 onClick={() => this.handleClick("D3M2")}>
            D3M2 - Information Systems Acquisition and Development
          </h3>
          <h3 onClick={() => this.handleClick("D3M3")}>
            D3M3 - Information Systems Implementation
          </h3>
          <h3 onClick={() => this.handleClick("D4M1")}>
            D4M1 - Information Systems Operations
          </h3>
          <h3 onClick={() => this.handleClick("D4M2")}>
            D4M2 - Information Systems Service Management
          </h3>
          <h3 onClick={() => this.handleClick("D4M3")}>
            D4M3 - Information Systems Business Resilience and Maintenance Mgmt Part 1
          </h3>
          <h3 onClick={() => this.handleClick("D4M4")}>
            D4M4 - Information Systems Business Resilience and Maintenance Mgmt Part 2
          </h3>
          <h3 onClick={() => this.handleClick("D5M1")}>
            D5M1 - Information Asset Security and Control Part 1
          </h3>
          <h3 onClick={() => this.handleClick("D5M2")}>
            D5M2 - Information Asset Security and Control Part 2
          </h3>
          <h3 onClick={() => this.handleClick("D5M3")}>
            D5M3 - Information Asset Security and Control Part 3
          </h3>
          <h3 onClick={() => this.handleClick("D5M4")}>
            D5M4 - Information Asset Security and Control Part 4
          </h3>
          <h3 onClick={() => this.handleClick("D5M5")}>
            D5M5 - Information Asset Security and Control Part 5
          </h3>
          <h3 onClick={() => this.handleClick("D5M6")}>
            D5M6 - Security Event Management
          </h3>
        </div>
        
      </div>
    );
  }
}

export default Modules;
