import "./GIT.css";
import React from "react";
import Title from "./Title";
import Dots from "./Dots";
import Flashcard from "./Flashcard";
import Modules from "./Modules";

class GIT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: [],
      title: "",
      deck: [],
      subDomainTitle: "",
      currentIndex: -1,
      card: {},
      side: "front",
    };

    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleFlip = this.handleFlip.bind(this);
    this.handleLeft = this.handleLeft.bind(this);
    this.handleRight = this.handleRight.bind(this);
    this.handleLinkSelect = this.handleLinkSelect.bind(this);
    this.handleSubModuleChange = this.handleSubModuleChange.bind(this);
  }

  componentDidMount() {
    //grab stack along with entry
    fetch(`https://www.wattmatters.us/git/GIT01`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          module: data["GIT01"],
          title: "GIT01",
          deck: data["GIT01"][0].subdomains,
          subDomainTitle: data["GIT01"][0].topic,
          currentIndex: 0,
          card: data["GIT01"][0].subdomains[0],
        });
      });
  }

  handleSwipe(index, cardObject, reset) {
    this.setState({
      currentIndex: index,
      card: cardObject,
      side: "front",
    });
    console.log(cardObject);
  }

  handleFlip(flip) {
    if (flip === "front") {
      this.setState({ side: "front" });
    } else {
      this.setState({ side: "back" });
    }
  }

  handleLeft() {
    if (this.state.currentIndex === -1 || this.state.currentIndex === 0) {
      let currentIndexClicked = this.state.deck.length - 1;
      this.setState({
        card: this.state.deck[currentIndexClicked],
        currentIndex: currentIndexClicked,
        side: "front",
      });
    } else {
      let currentIndexClicked = this.state.currentIndex - 1;
      this.setState({
        card: this.state.deck[currentIndexClicked],
        currentIndex: currentIndexClicked,
        side: "front",
      });
      console.log(currentIndexClicked);
    }
  }

  handleRight() {
    if (
      this.state.currentIndex === -1 ||
      this.state.currentIndex === this.state.deck.length - 1
    ) {
      let currentIndexClicked = 0;
      this.setState({
        card: this.state.deck[currentIndexClicked],
        currentIndex: currentIndexClicked,
        side: "front",
      });
    } else {
      let currentIndexClicked = this.state.currentIndex + 1;
      this.setState({
        card: this.state.deck[currentIndexClicked],
        currentIndex: currentIndexClicked,
        side: "front",
      });
      console.log(currentIndexClicked);
    }
  }

  handleLinkSelect(module) {
    console.log(module);
    fetch(`https://www.wattmatters.us/git/${module}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        /*this.setState({
          deck: data[`${module}`],
          currentIndex: 0,
          card: data[`${module}`][0],
          side: "front",
        });*/
        this.setState({
          module: data[`${module}`],
          title: `${module}`,
          deck: data[`${module}`][0].subdomains,
          subDomainTitle: data[`${module}`][0].topic,
          currentIndex: 0,
          card: data[`${module}`][0].subdomains[0],
          side: "front",
        });
      });
  }

  handleSubModuleChange(subMod) {
    console.log(subMod);
    this.setState({
      deck: subMod.subdomains,
      subDomainTitle: subMod.topic,
      currentIndex: 0,
      card: subMod.subdomains[0],
      side: "front",
    });
  }

  render() {
    return (
      <div>
        

        <div className="main-container">
        
          <Title titleName={this.state.title} subDomainName={this.state.subDomainTitle} passedDeck={this.state.module} subModuleClick={this.handleSubModuleChange}/>
          <Modules passedModule={this.handleLinkSelect} passedDeck={this.state.module} subModuleClick={this.handleSubModuleChange}/>
          <Flashcard
            card={this.state.card}
            cardStatus={this.state.side}
            flipCard={this.handleFlip}
          />
          <div className="bottom">
            <Dots
              cards={this.state.deck}
              currentIndex={this.state.currentIndex}
              passedIndex={this.handleSwipe}
            />

            <div className="arrow-container">
              <div id="left" onClick={this.handleLeft}>
                <div className="arrow"> &lt; </div>
              </div>
              <div id="right" onClick={this.handleRight}>
                <div className="arrow"> &gt; </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GIT;
