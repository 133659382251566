import React from "react";
import "./Submodules.css";

class Submodules extends React.Component {
  

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(submodule) {
    this.props.subModuleClick(submodule);
  }

  render() {
    console.log(this.props.passedDeck);
    
    return this.props.passedDeck.map((subtopic, index) => {
      console.log(subtopic.topic)
      return <h3 key={index} onClick={() => this.handleClick(subtopic)}> {subtopic.topic}</h3>
    })
    
    }
  
}

export default Submodules;