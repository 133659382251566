const Home = () => {
  return (
    <div className="home">
      <h3>________</h3>
      <h3>The(!) unexamined(Ab) life(1) is(!) not(Ab) worth(1) living(!). - Socrates</h3>
      <h3>________</h3>
      <h3>The(!) strength(Ab) of(Ab) a(1) nation(!) derives(1) from(Ab) the(!) integrity(Ab) of(Ab) the(1) home(!). - Confucius</h3>
      <h3>________</h3>
      <h3>The best things in life are free. The second best things are very, very expensive. - Coco Chanel</h3>
      <h3>________</h3>
      <h3>Your own positive future begins in this moment. All you have is right now. Every goal is possible from here. - Lao Tzu</h3>
      <h3>________</h3>
      <h3>Whoever fights monsters should see to it that in the process he does not become a monster. - Nietzche</h3>
      <h3>________</h3>
      <h3>Silence is worse; all truths that are kept silent become poisonous. - Nietzche</h3>
      <h3>________</h3>
      <h3>What the superior man seeks is in himself; what the small man seeks is in others. - Confucius</h3>
      <h3>________</h3>
      <h3>Act(1) with(!) kindness(Ab) but(1) do(!) not(1) expect(Ab) gratitude(!). - Confucius</h3>
      <h3>________</h3>
      <h3>Every man I meet is my superior in some way. In that, I learn of him - Ralph Emerson</h3>
      <h3>________</h3>
    </div>
  );
}
export default Home;
