import React from "react";
import "./Flashcard.css";

class Flashcard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.cardStatus === "front") {
      this.props.flipCard("back");
    } else {
      this.props.flipCard("front");
    }
  }

  render() {
    if (this.props.cardStatus === "front") {
      return (
        <div className="card" onClick={this.handleClick}>
          <div className="front" >
            <h2 >{this.props.card.question}</h2>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card" onClick={this.handleClick}>
          <div className="back">
            { this.props.card.answer.map((item) => {return <h2>{item}</h2>}
            )}
            
          </div>
        </div>
      );
    }
  }
}

export default Flashcard;
