import React from "react";

class Dot extends React.Component {
  constructor(props) {
    super(props);

    

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.passedIndex(this.props.keyIndex, this.props.card, true);
   
  }

  render() {
    if (this.props.currentIndex === this.props.keyIndex) {
        return <span>●</span>;
      } else {
        return <span onClick={this.handleClick}>○ </span>;
      }
    
  }
}

export default Dot;
