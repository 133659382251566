import React from "react";
import "./Dots.css";
import Dot from './Dot';

class Dots extends React.Component {




  render() {
    return (
      <div className="dots">
        {this.props.cards.map((val, index) => {
          
          return <Dot key={index} currentIndex={this.props.currentIndex} keyIndex={index} card={val} passedIndex={this.props.passedIndex} />;
          })
        }
        
      </div>
    );
  }
}

export default Dots;
