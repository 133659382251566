import React from "react";
import "./Title.css";
import jelly from "./jelly.png";
import Submodules from "./Submodules";

class Title extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subMenu: "subModules"
    };
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.handleSubModClick = this.handleSubModClick.bind(this);
  }

  toggleHamburger() {
    if (this.state.subMenu === "subModules") {
      this.setState({
        subMenu: "subModules activeSub",
      });
    } else {
      this.setState({        
        subMenu: "subModules"
      });
    }
  }

  handleSubModClick(submodule) {
    this.props.subModuleClick(submodule);

    /*this.setState({
      navMenu: "modules",
    });*/
    this.setState({
      
      subMenu: "subModules"
    });
  }

  render() {
    return (
      <div className="title">
        <div className="title-block">
          <h1>CISA Flash Cards</h1>
          <div className="subtitle">{this.props.titleName} - {this.props.subDomainName} </div>
        </div>

        <img src={jelly} alt="Jelly Belly" onClick = {this.toggleHamburger}></img>
        
        <div className={this.state.subMenu}>
          <Submodules
            passedDeck={this.props.passedDeck}
            subModuleClick={this.handleSubModClick}
          />
        </div>
      </div>
    );
  }
}

export default Title;
